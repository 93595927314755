import { ChainId } from './chains'
import { Token } from '../sdk-core/entities/token'
import { NativeCurrency } from '../sdk-core/entities/nativeCurrency'
import invariant from 'tiny-invariant'
import { WETH9_ADDRESS } from './addresses'

export const WBONE = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, WETH9_ADDRESS[ChainId.MAINNET], 18, 'wBONE', 'Wrapped Bone'),
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, WETH9_ADDRESS[ChainId.TESTNET], 18, 'wBONE', 'Wrapped Bone'),
}
export const WETH9 = WBONE

export class Bone extends NativeCurrency {
  protected constructor(chainId: number) {
    super(chainId, 18, 'BONE', 'Bone')
  }

  public get wrapped(): Token {
    const weth9 = WBONE[this.chainId as ChainId]
    invariant(!!weth9, 'WRAPPED')
    return weth9
  }

  private static _etherCache: { [chainId: number]: Bone } = {}

  public static onChain(chainId: number): Bone {
    return this._etherCache[chainId] ?? (this._etherCache[chainId] = new Bone(chainId))
  }

  public equals(other: NativeCurrency | Token): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}
