export enum ChainId {
  MAINNET = 109,
  TESTNET = 7701,
}

export const NETWORK_URLS: {
  [chainId in ChainId]: string
} = {
  [ChainId.MAINNET]: `https://www.shibrpc.com`,
  [ChainId.TESTNET]: `https://eth.plexnode.wtf`,
}
